import * as React from "react";
import styled from "styled-components";
import { Helmet } from "react-helmet";

import PageBlock from "../components/Layout/PageBlock";
import PageLayout from "../components/Layout/PageLayout";
import Breadcrumb from "../components/Breadcrumb/Breadcrumb";

import { APPLY_LINK } from "../helpers/links";

import { ArrowRight, ExternalLink } from "react-feather";
import { PageBlockTitle, CTALink, PageBlockNextPageDivider, primaryColour } from "../styles/component-stylings";
import { StaticImage } from "gatsby-plugin-image";

const PAGE_TITLE = "Careers";

const ColumnBlockContainer = styled.div`
    width: 100%;
    padding-bottom: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
`;

const IconContainer = styled.div`
    height: 200px;
    flex: 1 0 calc(100% / 4);
    padding: 1rem;
    border: 1px solid rgba(0, 0, 0, 0.125);

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: all 100ms ease-in;

    & > p {
        text-align: center;
        margin-bottom: 0;
    }

    &:hover {
        filter: brightness(0.9);
        box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.2);
    }
`;

const IconImgContainer = {
    height: "120px",
    width: "120px",
};

const CareersPage = ({ pageContext }) => {
    return (
        <PageLayout pageTitle={PAGE_TITLE} pageContext={pageContext}>
            <Helmet>
                <meta name='description' content='Careers overview page for Digital Surveillance Collection from the Australian Federal Police' />
            </Helmet>
            <PageBlock pageContext={pageContext} backgroundColour='white' isFirstBlock>
                <Breadcrumb pageContext={pageContext} />
                <h1>{PAGE_TITLE}</h1>
                <p>
                    DSC produces exceptional results because of exceptional people. As the criminal landscape changes, so too do our skills and solutions.
                    Everyone who works here makes a difference in keeping Australia safe, whilst having exceptional working conditions.
                </p>
                <CTALink to={APPLY_LINK} target='_blank' rel='external'>
                    <span>Apply now</span>
                    <ExternalLink />
                </CTALink>

                <PageBlockTitle>Benefits</PageBlockTitle>
                <ColumnBlockContainer>
                    {/* images from UXWing */}
                    <IconContainer>
                        <StaticImage style={IconImgContainer} objectFit='contain' src='../images/design-thinking.svg' alt='Design Thinking' />
                        <p>Engaging and Stimulating Work</p>
                    </IconContainer>
                    <IconContainer>
                        <StaticImage style={IconImgContainer} objectFit='contain' src='../images/remote-work.svg' alt='Remote Work' />
                        <p>Flexible Work Arrangements</p>
                    </IconContainer>
                    <IconContainer>
                        <StaticImage style={IconImgContainer} objectFit='contain' src='../images/financial-growth.svg' alt='Competitive Salary' />
                        <p>Competitive Salaries</p>
                    </IconContainer>
                    <IconContainer>
                        <StaticImage style={IconImgContainer} objectFit='contain' src='../images/personal-growth.svg' alt='Personal Growth' />
                        <p>Supported Growth Opportunities</p>
                    </IconContainer>
                    <IconContainer>
                        <StaticImage style={IconImgContainer} objectFit='contain' src='../images/information-technology.svg' alt='Technology' />
                        <p>Engage the latest technologies</p>
                    </IconContainer>
                    <IconContainer>
                        <StaticImage style={IconImgContainer} objectFit='contain' src='../images/globe.svg' alt='Globe' />
                        <p>Make A Difference</p>
                    </IconContainer>
                    <CTALink style={{ marginTop: "1rem" }} to='/careers/benefits' rel='next'>
                        <span>See more benefits</span>
                        <ArrowRight />
                    </CTALink>
                </ColumnBlockContainer>
                <PageBlockTitle>Culture</PageBlockTitle>
                <p>
                    We are proud of our diverse and flexible work culture, where our people can work happily and comfortably on some of Australia's most
                    challenging problems.
                </p>
                <CTALink to='/careers/culture' rel='next'>
                    <span>Learn more</span>
                    <ArrowRight />
                </CTALink>
                <PageBlockTitle>Our People</PageBlockTitle>
                <p>
                    Our people are our greatest asset, and we have some of the brightest and most innovative people working on many varied and difficult
                    problems.
                </p>
                <CTALink to='/careers/our-people' rel='next'>
                    <span>Meet us</span>
                    <ArrowRight />
                </CTALink>
                <PageBlockTitle>Opportunities</PageBlockTitle>
                <p>
                    We are constantly expanding and growing to provide the technical support that the AFP requires. No matter where you are in your career, see
                    how you fit in.
                </p>
                <CTALink to='/careers/opportunities' rel='next'>
                    <span>Discover careers</span>
                    <ArrowRight />
                </CTALink>
                <PageBlockTitle>Dandelion Program</PageBlockTitle>
                <p>
                    The Dandelion Program provides specialised recruitment and support services for members of the neurodivergent community to join a 2-year
                    traineeship with DSC.
                </p>
                <CTALink to='/careers/dandelion-program' rel='next'>
                    <span>Explore the Dandelion Program</span>
                    <ArrowRight />
                </CTALink>
                <PageBlockTitle>Technical Specialist Framework (TSF)</PageBlockTitle>
                <p>
                    DSC is unique among government organisations in that we are one of the only organisation that rewards highly specialised and skilled
                    technical experts through our Technical Specialist Framework. Think private sector pay with public service benefits without the management
                    responsibility.
                </p>
                <CTALink to='/careers/technical-specialist-framework' rel='next'>
                    <span>Explore TSF</span>
                    <ArrowRight />
                </CTALink>
                <PageBlockNextPageDivider>
                    <CTALink $invert $borderColour={primaryColour} to='/contact-us' rel='next'>
                        <span>Contact us</span>
                        <ArrowRight />
                    </CTALink>
                </PageBlockNextPageDivider>
            </PageBlock>
        </PageLayout>
    );
};

export default CareersPage;
